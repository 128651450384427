let config = {
    s3: {
        REGION: "ap-southeast-2",
        BUCKET: "forwood-newcrest-testing-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-newcrest-testing-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "ap-southeast-2",
        URL: "https://lhngbwrbt5cxnpw3iww3qawwea.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "ap-southeast-2",
        URL: "https://ixy1qzwcc5.execute-api.ap-southeast-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://tt1obljbs0.execute-api.ap-southeast-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.testing.newcrest.forwoodsafety.com",
        WEBSOCKET: "wss://lssvqv4m17.execute-api.ap-southeast-2.amazonaws.com/testing"
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_vpNG1OXId",
        APP_CLIENT_ID: "1t8b2lefk50jm351fg95r4unhb",
        IDENTITY_POOL_ID: "ap-southeast-2:68ebd875-a97d-43f7-9110-b5c2ec85d48c",
        USERPOOL_HOSTED_DOMAIN: "forwood-newcrest-id-testing",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.testing.newcrest.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.testing.newcrest.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::682129469866:role/testing-CA",
        get USERPOOL_ADFS_LAUNCH_URL () {
          return 'https://'+this.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+this.APP_CLIENT_ID;
        }
    },
    samlProvider: {
      NAME: 'ForwoodAzure',
      SUPPORTED_LIST: 'ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.34.6",
        HOSTNAME: "id.testing.newcrest.forwoodsafety.com",
        COOKIE_DOMAIN: ".testing.newcrest.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "44302b9b-5168-4406-9c98-35b636528121",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.testing.newcrest.forwoodsafety.com"
    }
};

export default config;
